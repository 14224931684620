@use '/src/core-sass/style' as s;

#get-in-touch {
  position: relative;
  min-height: calc(100vh - #{s.$XXXXL});

  .top-area {
    height: s.$XXXXL;
    background: linear-gradient(30deg, s.colors(lightOrange), s.colors(darkOrange));
  }

  .get-in-touch-content {
    display: grid;
    grid-template-columns: auto 46%;
    min-height: calc(100vh - #{s.$XXXXL});

    .address-container {
      display: flex;
      flex-direction: column;
      gap: s.$L;
      padding-top: s.$L;

      .map-container {
        display: flex;
        flex-direction: column;
        gap: s.$M;

        .map {
          position: relative;
          height: s.$XXXL + s.$S;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border: 1px solid s.colors(mediumGrey);
          border-radius: 8px;

          img, iframe {
            object-fit: cover;
            width: 100%;
            border: none;
          }
        }
      }
    }

    .contact-us-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: s.colors(lightGrey);
      border: 1px solid s.colors(mediumGrey);
      gap: s.$L;
      padding-bottom: s.$XXL;

      .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: s.$S;
      }
    }
  }
}

@media only screen and (min-width: s.$width_lg) {
  #get-in-touch {
    .get-in-touch-content {
      .address-container {
        padding-right: s.$XL;
      }
    }
  }
}

@media only screen and (max-width: s.$width_lg) {
  #get-in-touch {
    .get-in-touch-content {
      grid-template-columns: repeat(1, 1fr);
      height: 100%;
    }
  }
}

@media only screen and (max-width: s.$width_md) {
  #get-in-touch {
    .contact-us-container {
      padding-left: s.$XL;
      padding-right: s.$XL;
    }
  }
}

@media only screen and (max-width: s.$width_xs) {
  #get-in-touch {
    .contact-us-container {
      padding-left: s.$L;
      padding-right: s.$L;
    }
  }
}

@media only screen and (max-width: s.$width_xxs) {
  #get-in-touch {
    .contact-us-container {
      padding-left: s.$S;
      padding-right: s.$S;
    }
  }
}
