@use '/src/core-sass/style' as s;

#build {
  display: flex;
  flex-direction: column;
  gap: s.$L * 2;
  background: linear-gradient(200deg, s.colors(lightOrange), s.colors(darkOrange));
  min-height: 40vh;

  h3 {
    color: s.colors(white);
    text-align: center;
  }

  .build-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: s.$XL;
  }
}

@media only screen and (max-width: s.$width_md) {
  #build {
    min0height: auto;

    .build-card-container {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: s.$L * 2;
    }
  }
}