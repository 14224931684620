@use '/src/core-sass/style' as s;

#list-item {
  li {
    list-style-type: none;
  }

  p, a {
    @include s.footerText(black);
  }
}