@use '/src/core-sass/colors' as c;

//FONT SIZES

//PIXEL
//$H1px: 73;
$H2px: 60;
$H3px: 48;
$H4px: 28;
$P1px: 24;
$P2px: 19;
$P3px: 16;
$P4px: 14;
$P5px: 12;

//EM
//$H1: ($H1px / 16) * 1em;
$H2: ($H2px / 16) * 1em;
$H3: ($H3px / 16) * 1em;
$H4: ($H4px / 16) * 1em;
$P1: ($P1px / 16) * 1em;
$P2: ($P2px / 16) * 1em;
$P3: ($P3px / 16) * 1em;
$P4: ($P4px / 16) * 1em;
$P5: ($P5px / 16) * 1em;

//FONT FAMILIES

//ROBOTO

@font-face {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url("../styles/fonts/Roboto-Regular.ttf") format("truetype");
}

//MONTSERRAT

@font-face {
  font-family: 'MontserratLight';
  font-style: normal;
  font-weight: 400;
  src: url("../styles/fonts/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../styles/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 700;
  src: url("../styles/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'MontserratItalic';
  font-style: italic;
  font-weight: 400;
  src: url("../styles/fonts/Montserrat-Italic.ttf") format("truetype");
}

//FONT STYLES

@mixin headline($fontColor: c.colors(darkGrey)) {
  font-family: MontserratBold, sans-serif;
  font-weight: 700;
  font-size: $H2;
  color: $fontColor;
  line-height: 1.22;
}

@mixin sectionTitle($fontColor: c.colors(darkGrey)) {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: $H3;
  line-height: 1.23;
  color: $fontColor;
  margin-top: 0;
  margin-bottom: 32px;
}

@mixin title($fontColor: c.colors(darkGrey)) {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: $H4;
  line-height: 1.22;
  color: $fontColor;
}

@mixin subTitle($fontColor: c.colors(darkGrey)) {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: $P4;
  line-height: 1.22;
  color: $fontColor;
}

@mixin breadText($fontColor: c.colors(darkGrey)) {
  font-family: Montserrat, sans-serif;
  font-size: $P1;
  line-height: 1.33;
  color: $fontColor;
}

@mixin quote($fontColor: c.colors(darkGrey)) {
  font-family: MontserratItalic, sans-serif;
  font-size: $P1;
  font-weight: 400;
  line-height: 1.33;
  color: $fontColor;
}

@mixin buttonText($fontColor: c.colors(darkGrey)) {
  font-family: MontserratBold, sans-serif;
  font-weight: 700;
  font-size: $P2;
  line-height: 1.1;
  color: $fontColor;
}

@mixin linkText($fontColor: c.colors(darkGrey)) {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: $P3;
  line-height: 1.1;
  color: $fontColor;
}

@mixin footerText($fontColor: c.colors(darkGrey)) {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: $P4;
  line-height: 1.83;
  color: $fontColor;
}