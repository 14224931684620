@use '/src/core-sass/style' as s;

#carousel {
  .responsive-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    img.background {
      @include s.slowAnimationTransition();
      object-fit: cover;
      min-height: 120%;
    }

    img.shimmer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      opacity: 0.3;
      z-index: 2;
      animation: fadeInShimmer 2s;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background-color: s.colors(overlayBlack);
      z-index: 1;
      opacity: 1;
      animation: fadeIn 2s;
    }

    .responsive-container__slimmer {
      position: absolute;
      right: 0;
      top: s.$XXXXL;
      z-index: 3;

      .headline {
        width: s.$XL * 2;
        text-align: right;
        color: s.colors(white);
        opacity: 1;
        animation: fadeIn 2s;
      }
    }

    .hover-logo {
      position: absolute;
      display: block;
      bottom: s.$L;
      left: s.$XXL;
      height: s.$XL * 2;
      width: s.$XL * 2;
      z-index: 4;
      opacity: 0;
      animation: fadeOut 2s;
    }

    &:hover {
      .overlay, .headline {
        opacity: 0;
        animation: fadeOut 2s;
      }

      .shimmer {
        opacity: 0;
        animation: fadeOutShimmer 2s;
      }

      .hover-logo {
        opacity: 1;
        animation: fadeIn 2s;
      }

      img.background {
        min-height: 150%;
      }
    }
  }

  .carousel {
    .control-dots {
      height: s.$M;
      display: flex;
      align-items: center;
      text-align: right;
      bottom: s.$L * 2;
      right: 87px + 14px + 23px;
      width: auto;
      margin: 0;

      .dot {
        background-color: s.colors(lightGrey);
        width: 16px;
        height: 16px;
        margin: 0 8px;

        &.selected {
          background-color: s.colors(orange);

          &:hover {
            background-color: s.colors(black);
            transition: all 0.4s ease;
          }
        }

        &:hover {
          background-color: s.colors(black);
          transition: all 0.4s ease;
        }
      }
    }

    .regularChevronRight, .regularChevronLeft {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: s.$L * 2;
      height: s.$M;
      width: 14px;
      color: s.colors(white);
      z-index: 4;
      cursor: pointer;

      &:hover {
        color: s.colors(orange);
        transition: all 0.4s ease;
      }
    }

    .regularChevronRight {
      right: 87px;
    }

    .thumbs-wrapper, .carousel-status, .control-arrow {
      display: none;
    }
  }
}

@media only screen and (max-width: s.$width_md) {
  #carousel {
    .carousel {
      .control-dots {
        right: 40px + 14px + 18px;

        .dot {
          width: 10px;
          height: 10px;
          margin: 0 5px;
        }
      }

      .regularChevronRight {
        right: 40px;
      }
    }
  }
}

@media only screen and (max-width: s.$width_xs) {
  #carousel {
    .responsive-carousel {
      .responsive-container__slimmer {
        .headline {
          width: s.$XXL;
        }
      }
    }
  }
}