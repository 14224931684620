@use '/src/core-sass/style' as s;

button.hexad-button {
  border: none;
}

#hexad-button {
  padding: s.$S + s.$XXS s.$L - s.$XXS;
  background-color: s.colors(white);
  width: fit-content;
  display: flex;
  border-radius: s.$XL - s.$XXS;
  color: s.colors(orange) !important;
  text-decoration: none;
  @include s.buttonText(s.colors(orange));
  text-align: center;

  &:hover {
    color: s.colors(black) !important;
  }

  &.inverted {
    background-color: s.colors(orange);
    color: s.colors(white) !important;

    &:hover {
      background-color: s.colors(hoverOrange);
    }
  }

  &.small {
    padding: s.$S s.$S + s.$XXS;
    font-size: s.$P3;
  }

  &.spaced {
    margin-bottom: s.$M;
    margin-top: s.$M;
  }
}
