@use '/src/core-sass/style' as s;

#who-we-are {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 40vh;
  gap: s.$XXXL - (s.$XS * 2);

  .intro {
    display: flex;
    flex-direction: column;
    gap: s.$L;
  }

  p.text-container {
    @include s.quote();
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    column-gap: s.$XL;
    row-gap: s.$S;
  }
}

@media only screen and (max-width: s.$width_xl) {
  #who-we-are {
    .features-grid {
      grid-template-columns: repeat(2, auto);
    }
  }
}

@media only screen and (max-width: s.$width_md) {
  #who-we-are {
    min-height: auto;

    .features-grid {
      grid-template-columns: repeat(1, auto);
    }
  }
}

@media only screen and (max-width: s.$width_xs) {
  #who-we-are {
    gap: s.$XXL;
  }
}