@use '/src/core-sass/style' as s;

#select-language {
  position: relative;
  cursor: pointer;

  .solidGlobe {
    font-size: s.$P2;
  }

  .language-dropdown {
    position: absolute;
    top: s.$M + s.$XXS;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    z-index: 10;
    background-color: s.colors(white);
    list-style: none;
    padding: s.$XS 0;
    margin: 0;
    border: 1px solid s.colors(mediumGrey);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    gap: s.$S;

    li {
      display: block;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        padding: s.$XXS s.$S;
      }
    }

    input + label {
      color: s.colors(black);
    }

    &:hover label {
      background-color: s.colors(highlightGrey);
    }
  }
}