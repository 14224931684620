//$width_mobile: 640px;
//$width_tablet: 768px;
//$width_laptop: 1366px;
//$width_high_res_laptop: 1920px;

$width_xxs: 320px;
$width_xs: 576px;
$width_md: 768px;
$width_lg: 992px;
$width_xl: 1200px;
$width_xxl: 1400px;
$width_xxxl: 1920px;

:export {
  width_xxs: $width_xxs / 1px;
  width_xs: $width_xs / 1px;
  width_md: $width_md / 1px;
  width_lg: $width_lg / 1px;
  width_xl: $width_xl / 1px;
  width_xxl: $width_xxl / 1px;
  width_xxxl: $width_xxxl / 1px;
}
