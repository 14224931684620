@use '/src/core-sass/style' as s;

#kit-consulting {
  .top-area {
    height: s.$XXXXL;
    background: linear-gradient(30deg, s.colors(lightOrange), s.colors(darkOrange));
  }

  .kit-consulting-bottom{
    background: linear-gradient(30deg, s.colors(lightOrange), s.colors(darkOrange));
    height: 576px;
    padding: 2px;
  }
  .kit-consulting-container {
    display: grid;
    grid-template-columns: auto 46%;

    .kit-consulting-bottom-banner{
      padding: 120px;
      gap: 40px;
    }

    .kit-consulting-content {
      display: flex;
      flex-direction: column;
      gap: s.$L;
      padding-top: s.$L;
    }

    .kit-consulting-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .headline-container {
        position: absolute;
        right: s.$L;
        top: s.$XXL;
        z-index: 3;

        .headline {
          width: s.$XXL * 2 - s.$S;
          text-align: right;
          color: s.colors(white);
        }
      }

      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: s.colors(overlayBlack);
        z-index: 2;
      }

      .background {
        object-fit: cover;
        min-height: 100%;
        overflow: hidden;
      }
    }
  }
  .info-buttons {
    display: block;

    a, li {
      text-decoration: none;
      color: s.colors(white);
      text-align: center;

      &:hover {
        color: s.colors(black);
      }
    }

    ul {
      display: flex;
      align-items: center;
      column-gap: s.$S;
      list-style-type: none;
      margin: 0;
    }
  }
}

@media only screen and (max-width: s.$width_lg) {
  #kit-consulting {
    height: unset;

    .kit-consulting-container {
      grid-template-columns: repeat(1, 1fr);

      .kit-consulting-image {
        height: s.$XXL * 6;

        .headline h4 {
          font-size: s.$P2;
        }
      }
    }
  }
}
