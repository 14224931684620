//SIZES

//PIXEL
$XXSpx: 4;
$XSpx: 10;
$Spx: 16;
$Mpx: 24;
$Lpx: 40;
$XLpx: 60;
$XXLpx: 90;
$XXXLpx: 140;
$XXXXLpx: 180;

//EM
$XXS: ($XXSpx / 16) * 1em;
$XS: ($XSpx / 16) * 1em;
$S: ($Spx / 16) * 1em;
$M: ($Mpx / 16) * 1em;
$L: ($Lpx / 16) * 1em;
$XL: ($XLpx / 16) * 1em;
$XXL: ($XXLpx / 16) * 1em;
$XXXL: ($XXXLpx / 16) * 1em;
$XXXXL: ($XXXXLpx / 16) * 1em;

//Ready to use in JS
:export {
  sizeXXS: $XXSpx;
  sizeXS: $XSpx;
  sizeS: $Spx;
  sizeM: $Mpx;
  sizeL: $Lpx;
  sizeXL: $XLpx;
  sizeXXL: $XXLpx;
  sizeXXXL: $XXXLpx;
  sizeXXXXL: $XXXXLpx;
}
