@use '/src/core-sass/style' as s;

.build-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: s.$L;

  .title {
    display: flex;
    flex-direction: column;
    gap: s.$M;
  }

  h4, p {
    color: s.colors(white);
  }

  .hexad-button {
    align-self: center;
  }
}
