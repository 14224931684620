@use '/src/core-sass/style' as s;

#hexad-header {
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: s.$XXXXL;
  z-index: 999;
  transition: all 210ms cubic-bezier(0.4, 0.14, 0.3, 1);

  a.logo-link {
    display: flex;
    align-items: center;

    .hexad-logo {
      height: s.$L;
    }
  }

  .full-menu {
    display: block;

    a, li {
      text-decoration: none;
      color: s.colors(white);
      text-align: center;

      &:hover {
        color: s.colors(black);
      }
    }

    ul {
      display: flex;
      align-items: center;
      column-gap: s.$S;
      list-style-type: none;
      margin: 0;
    }
  }

  .burger-menu {
    display: none;
  }
}

.burger-dropdown {
  display: none;
}

.fixed #hexad-header {
  height: s.$XXL;
  background: s.colors(white);
  box-shadow: 0px 5px 10px s.colors(shadow);

  a, span {
    color: s.colors(orange);
  }

  &.dropdown-open {
    background: s.colors(white);
  }

  .full-menu {
    ul {
      margin: s.$M;
    }
  }
}

@media only screen and (max-width: s.$width_lg) {
  #hexad-header {
    .full-menu {
      display: none;
    }

    .burger-menu {
      display: inline-block;
      color: s.colors(white);

      .regularBars, .regularXmark {
        font-size: s.$H4 + s.$P5;
      }
    }
  }

  .fixed #hexad-header {
    &.dropdown-open {
      background: s.colors(shimmerOrange);
    }
    .burger-menu {
      color: s.colors(orange);
    }
  }

  .burger-dropdown {
    display: block;
    position: fixed;
    top: s.$XXL;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: s.colors(white);
    box-shadow: 0px 5px 10px s.colors(shadow);
    transition: all 210ms cubic-bezier(0.4, 0.14, 0.3, 1);
    padding-top: s.$M;
    padding-bottom: s.$M;

    ul {
      padding-left: 0;
    }

    a {
      display: block;
      color: s.colors(orange);
      padding: 0;
    }

    li {
      text-decoration: none;
      color: s.colors(orange);
      list-style-type: none;
      padding: s.$XS 0;
    }

    .language-link {
      li {
        display: block;
        @include s.linkText();
        color: s.colors(orange);
        padding: 0;

        input {
          display: none;
        }

        label {
          cursor: pointer;
          display: block;
        }
      }
    }

    .link-button {
      @include s.buttonText(s.colors(orange));
      font-size: s.$P3;
    }

    &.scrolling {
      top: s.$XXXXL;
    }
  }
}
