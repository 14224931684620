@mixin slowAnimationTransition() {
  transition: all 2s ease;
  -webkit-transition: all 2s ease;
}

@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeOutShimmer {
  0% { opacity: 0.3 }
  100% { opacity: 0 }
}

@keyframes fadeInShimmer {
  0% { opacity: 0 }
  100% { opacity: 0.3 }
}