@use '/src/core-sass/style' as s;

#services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: s.$XXXL - s.$XS * 2;
  min-height: 100vh;

  .intro {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: s.$width_md) {
  #services {
    min-height: auto;
  }
}

@media only screen and (max-width: s.$width_xs) {
  #services {
    text-align: left;
    align-items: flex-start;
    gap: s.$XXL;

    .intro {
      gap: s.$L;
    }
  }
}

#hexad-button-group-service {
  padding: s.$S + s.$XXS s.$L - s.$XXS;
  background-color: s.colors(white);
  width: fit-content;
  display: flex;
  border-radius: s.$XL - s.$XXS;
  border: 1px solid;
  border-color: s.colors(orange);
  padding: 5px;
  color: s.colors(orange) !important;
  text-decoration: none;
  @include s.buttonText(s.colors(orange));
  text-align: center;

  &:hover {
    color: s.colors(black) !important;
  }

  &.inverted {
    background-color: s.colors(orange);
    color: s.colors(white) !important;

    &:hover {
      background-color: s.colors(hoverOrange);
    }
  }

  &.small {
    padding: s.$S s.$S + s.$XXS;
    font-size: s.$P3;
  }
}