@use '/src/core-sass/style' as s;

#small-image-section {
  position: relative;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  display: block;
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.4), inset 0px 4px 4px rgba(0, 0, 0, 0.4);

  .parallax {
    background-image: url("../../../../styles/images/Barcelona.jpg");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: s.$width_xs) {
  #small-image-section {
    display: none;

    .parallax {
      background-attachment: scroll;
    }
  }
}