@use '/src/core-sass/style' as s;

#contact-form {
  display: flex;
  flex-direction: column;
  gap: s.$M;

  .contact-name {
    display: flex;
    justify-content: space-between;
    column-gap: s.$S;
    row-gap: s.$M;
  }

  .contact-pair {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: s.$XXS;

    input {
      height: s.$L + s.$XXS;
    }

    input, textarea {
      border: 1px solid s.colors(mediumGrey);
      border-radius: s.$XS;
      padding-left: s.$XS;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      &::placeholder {
        color: s.colors(mediumGrey);
      }
    }

    textarea {
      font-family: inherit;
      font-size: s.$P4;
      padding-top: s.$XS;
    }
  }

  .error-message {
    position: absolute;
    bottom: -(s.$M);
    left: 0;
    color: s.colors(hoverOrange);
    font-size: s.$P4;
  }

  .submit-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: s.$S;

    .hexad-button {
      align-self: center;
    }

    .error-message {
      top: s.$XXL - s.$XS;
      right: 0;
      text-align: center;
    }
  }

  .error {
    input {
      border-color: s.colors(hoverOrange);
    }

    input[type=checkbox]::before {
      border-color: s.colors(hoverOrange);
    }
  }

  .terms-and-conditions {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;

    input {
      align-self: flex-start;
      margin: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      outline: none;
      content: none;
    }

    input:before {
      font-family: "FontAwesome";
      content: "\f00c";
      font-size: 15px;
      color: transparent !important;
      background: white;
      display: block;
      width: 15px;
      height: 15px;
      border: 1px solid s.colors(mediumGrey);
      border-radius: 2px;
      margin: 0 s.$XS 0 0;
      align-self: flex-start;
    }

    input:checked:before {
      color: s.colors(orange) !important;
    }

    //.error {
    //  input[type=checkbox]::before {
    //    border-color: s.colors(hoverOrange);
    //  }
    //}

    p {
      display: inline-block;
      @include s.footerText();
      line-height: 1.33;
    }

    a {
      color: s.colors(orange);
    }

    a:hover {
      color: s.colors(darkGrey);
    }
  }
}

@media only screen and (max-width: s.$width_md) {
  #contact-form {
    .contact-name {
      flex-direction: column;
    }
  }
}
