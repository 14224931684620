@use '/src/core-sass/style' as s;

#intro-section {
  display: grid;
  grid-template-columns: auto 37%;
  background: linear-gradient(30deg, s.colors(lightOrange), s.colors(darkOrange));
  min-height: 100vh;
  grid-column-gap: s.$L;

  .intro-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: s.$L;
    margin-top: s.$XXL;

    h2, p {
      color: s.colors(white);
    }

    h2 {
      span {
        background: s.colors(highlightGradient);
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(255, 100, 21, 0.3);
      }
    }

    p.text-container {
      padding-left: 0;
    }
  }

  img {
    height: 80vh;
    margin-bottom: s.$M;
    align-self: flex-end;
    justify-self: flex-end;
  }
}

@media only screen and (min-width: s.$width_lg) {
  #intro-section {
    .intro-content {
      padding-right: unset;
    }
  }
}

@media only screen and (min-width: s.$width_lg) {
  #intro-section {
    .intro-content {
      padding-right: s.$L;
    }
  }
}

@media only screen and (max-width: s.$width_lg) {
  #intro-section {
    display: flex;

    img {
      display: none;
    }
  }
}
