@use '/src/core-sass/style' as s;

#footer {
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: s.$L;
  column-gap: s.$S;
  background-color: s.colors(darkBackground);
  justify-items: center;

  .hexad-logo {
    height: s.$L;
  }

  h5, p, a {
    color: s.colors(white);
  }

  a:hover {
    color: s.colors(orange);
  }

  h4 {
    @include s.buttonText(white);
  }

  .social, .locations {
    h4 {
      margin-bottom: s.$S;
    }
  }
}

@media only screen and (max-width: s.$width_xl) {
  #footer {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;

    #contact {
      grid-column-start: 2;
      grid-row-end: span 2;
    }
    .locations, .social {
      grid-column-start: 3;
    }
  }
}

@media only screen and (max-width: s.$width_md) {
  #footer {
    grid-template-columns: repeat(1, 1fr);

    #contact, .locations, .social {
      grid-column-start: 2;
    }
  }
}

@media only screen and (max-width: s.$width_xs) {
  #footer {
    grid-template-columns: 1fr;

    #contact, .locations, .social {
      grid-column-start: 1;
    }
  }
}