@use '/src/core-sass/style' as s;

#about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 40vh;
  background-color: s.colors(lightGrey);
  gap: s.$L;
}

@media only screen and (max-width: s.$width_xs) {
  #about-us {
    text-align: right;
    align-items: flex-end;
    min-height: auto;
  }
}