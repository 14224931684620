@use '/src/core-sass/style' as s;

#legal-notice {
  .top-area {
    height: s.$XXXXL;
    background: linear-gradient(30deg, s.colors(lightOrange), s.colors(darkOrange));
  }

  .legal-notice-container {
    padding-top: s.$L;
    display: flex;
    flex-direction: column;
    gap: s.$L;

    .blocks {
      display: flex;
      flex-direction: column;
      gap: s.$XL;

      ul {
        display: inline-flex;
        flex-direction: column;
        margin: 0 auto 0 0;
        background: s.colors(lightGrey);
        padding: s.$M;
        gap: s.$S;

        li {
          @include s.linkText();
          list-style-type: none;
          display: inline-flex;
          flex-direction: column;
          line-height: 1.33;
        }
      }

      h4 {
        padding-bottom: s.$S;
      }
    }
  }
}