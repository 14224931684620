@use '/src/core-sass/style' as s;

#error-page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top-area {
    height: s.$XXXXL;
    background: linear-gradient(30deg, s.colors(lightOrange), s.colors(darkOrange));
  }

  .error-container {
    position: absolute;
    top: s.$XXL * 3;
    left: s.$XXL * 2;
    display: flex;
    flex-direction: column;
    gap: s.$XL;
    width: 28%;

    h2, h3 {
      margin: 0;
    }
  }

  img {
    padding-top: 30vh;
    width: 100%;
  }
}

@media only screen and (max-width: s.$width_lg) {
  #error-page {
    .error-container {
      left: s.$XXL;
      width: 50%;
    }
  }
}

@media only screen and (max-width: s.$width_xs) {
  #error-page {
    min-height: 70vh;

    .error-container {
      left: s.$L;
      width: 80%;
    }

    img {
      padding-top: 60vh;
    }
  }
}