@use '/src/core-sass/style' as s;

#clients-logo-container {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .image-gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: s.$S;

    > li {
      display: inline-flex;
      height: 160px;
      background: s.colors(lightGrey);
      border-radius: 8px;
      width: 278px;
    }

    li img {
      object-fit: scale-down;
      padding: 40px;
      width: 198px ;
    }
  }
}

@media only screen and (max-width: s.$width_md) {
  #clients-logo-container {
    .image-gallery {
      > li {
        height: 120px;
        width: 208px;
      }

      li img {
        padding: 24px;
        width: 160px ;
      }
    }
  }
}
