@use '/src/core-sass/style' as s;

#images-container {
  display: flex;
  flex-direction: column;
  gap: s.$L;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: s.$L;
    overflow: hidden;
    @include s.slowAnimationTransition();

    > li {
      flex-basis: s.$XXL + s.$S;
    }

    li img {
      object-fit: scale-down;
      width: 104px;
      height: 104px;
      vertical-align: middle;
      padding: s.$XXS;
      border-radius: 8px;
      background: s.colors(white);
    }
  }

  .hexad-button {
    align-self: center;
  }
}

@media only screen and (max-width: s.$width_md) {
  #images-container {
    .images-container {
      gap: s.$M;
    }
  }
}