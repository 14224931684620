//FONT AWESOME

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style : normal;
  src: url("../styles/fonts/fa-regular-400.eot") format("embedded-opentype"),
  url("../styles/fonts/fa-regular-400.woff2") format("woff2"),
  url("../styles/fonts/fa-regular-400.woff") format("woff"),
  url("../styles/fonts/fa-regular-400.ttf") format("truetype"),
  url("../styles/fonts/fa-regular-400.svg") format("svg");
}

@font-face {
  font-family: "FontAwesome-light";
  font-weight: 300;
  font-style : normal;
  src: url("../styles/fonts/fa-light-300.eot") format("embedded-opentype"),
  url("../styles/fonts/fa-light-300.woff2") format("woff2"),
  url("../styles/fonts/fa-light-300.woff") format("woff"),
  url("../styles/fonts/fa-light-300.ttf") format("truetype"),
  url("../styles/fonts/fa-light-300.svg") format("svg");
}

@font-face {
  font-family: "FontAwesome-brands";
  font-weight: normal;
  font-style : normal;
  src: url("../styles/fonts/fa-brands-400.eot") format("embedded-opentype"),
  url("../styles/fonts/fa-brands-400.woff2") format("woff2"),
  url("../styles/fonts/fa-brands-400.woff") format("woff"),
  url("../styles/fonts/fa-brands-400.ttf") format("truetype"),
  url("../styles/fonts/fa-brands-400.svg") format("svg");
}

@font-face {
  font-family: "FontAwesome-duotone";
  font-weight: 900;
  font-style : normal;
  src: url("../styles/fonts/fa-duotone-900.eot") format("embedded-opentype"),
  url("../styles/fonts/fa-duotone-900.woff2") format("woff2"),
  url("../styles/fonts/fa-duotone-900.woff") format("woff"),
  url("../styles/fonts/fa-duotone-900.ttf") format("truetype"),
  url("../styles/fonts/fa-duotone-900.svg") format("svg");
}

@font-face {
  font-family: "FontAwesome-solid";
  font-weight: 900;
  font-style : normal;
  src: url("../styles/fonts/fa-solid-900.eot") format("embedded-opentype"),
  url("../styles/fonts/fa-solid-900.woff2") format("woff2"),
  url("../styles/fonts/fa-solid-900.woff") format("woff"),
  url("../styles/fonts/fa-solid-900.ttf") format("truetype"),
  url("../styles/fonts/fa-solid-900.svg") format("svg");
}

.star:before {
  font-family: FontAwesome;
  content: "\f005";
}

.lightStar:before {
  font-family: FontAwesome-light;
  content: "\f005";
}

.solidStar:before {
  font-family: FontAwesome-solid;
  content: "\f005";
}

.solidEnvelope:before {
  font-family: FontAwesome-solid;
  content: '\f0e0';
}

.regularBars:before {
  font-family: FontAwesome-light;
  content: '\f0c9';
}

.regularXmark:before {
  font-family: FontAwesome-light;
  content: '\f00d';
}

.solidGlobe:before {
  font-family: FontAwesome-solid;
  content: '\f0ac';
}

.regularChevronRight:before {
  font-family: FontAwesome-solid;
  content: '\f054';
}

.regularChevronLeft:before {
  font-family: FontAwesome-solid;
  content: '\f053';
}
