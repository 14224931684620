@use '/src/core-sass/style' as s;

.feature-component {
  display: flex;
  gap: s.$S;
  text-align: left;
  inline-size: min-content;

  img {
    width: s.$M;
  }

  p {
    white-space: nowrap;
  }
}