@use '/src/core-sass/style' as s;

#clients {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  gap: s.$XXXL;

  .wider {
    display: flex;
    flex-direction: column;
    gap: s.$L;
  }
}

@media only screen and (max-width: s.$width_xs) {
  #clients {
    text-align: left;
    align-items: flex-start;
    gap: s.$XXL;
    min-height: auto;
  }
}

@media only screen and (max-width: s.$width_xxs) {
  #clients {
    text-align: left;
    align-items: flex-start;
  }
}
