@use '/src/core-sass/style' as s;

.body,
html {
  min-height: 100%;
  width: 100%;
  margin: auto;
  font-family: Roboto, Fallback, sans-serif;
  font-size: 16px;
}

@media only screen and (max-width: s.$width_xl) {
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: s.$width_xs) {
  body {
    font-size: 13px;
  }
}

@media only screen and (max-width: s.$width_xxs) {
  body {
    font-size: 12px;
  }
}

.App {
  height: 100vh;

  h1, h2, h3, h4, h5, p, a, ul, li {
    margin: 0;
    padding: 0;
  }

  h2 {
    @include s.headline();

  }

  h3 {
    @include s.sectionTitle();
  }

  h4 {
    @include s.title();
  }

  h5 {
    @include s.subTitle();
  }

  p {
    @include s.breadText();
  }

  a {
    @include s.linkText();
    text-decoration: none;
  }
}

.hero-container {
  height: auto;
  min-height: calc(100vh - #{s.$XXXXL});
}



@media only screen and (max-width: s.$width_lg) {
  .hero-container {
    height: 100%;
  }
}

.responsive-container {
  padding-top: s.$XXL;
  padding-bottom: s.$XXL;

  .slimmer, &__slimmer {
    padding-left: s.$XXXXL;
    padding-right: s.$XXXXL;
  }

  .wider, &__wider {
    padding-left: s.$XXXL;
    padding-right: s.$XXXL;
  }
}

p.text-container {
  padding-left: s.$XXL - s.$S;
  padding-right: s.$XXL - s.$S;
}

@media only screen and (max-width: s.$width_xl) {
  .responsive-container {
    padding-top: s.$XL;
    padding-bottom: s.$XL;

    .slimmer, &__slimmer {
      padding-left: s.$XXXL;
      padding-right: s.$XXXL;
    }

    .wider, &__wider {
      padding-left: s.$XXL;
      padding-right: s.$XXL;
    }
  }
}

@media only screen and (max-width: s.$width_lg) {
  .responsive-container {
    padding-top: s.$XL;
    padding-bottom: s.$XL;

    .slimmer, &__slimmer {
      padding-left: s.$XXL;
      padding-right: s.$XXL;
    }

    .wider, &__wider {
      padding-left: s.$XL;
      padding-right: s.$XL;
    }
  }

  p.text-container {
    padding-left: unset;
    padding-right: unset;
  }
}

@media only screen and (max-width: s.$width_xs) {
  .responsive-container {
    padding-top: s.$XXL;
    padding-bottom: s.$XXL;

    .slimmer, &__slimmer {
      padding-left: s.$L;
      padding-right: s.$L;
    }

    .wider, &__wider {
      padding-left: s.$L;
      padding-right: s.$L;
    }
  }
}

@media only screen and (max-width: s.$width_xxs) {
  .responsive-container {
    .slimmer, &__slimmer {
      padding-left: s.$S;
      padding-right: s.$S;
    }

    .wider, &__wider {
      padding-left: s.$S;
      padding-right: s.$S;
    }
  }
}