@use '/src/core-sass/style' as s;

#popup-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: s.colors(overlayBlack);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .alert-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: s.colors(white);
    width: 30vw;
    height: auto;
    gap: s.$L;
  }
}

@media only screen and (max-width: s.$width_md) {
  #popup-alert {
    .alert-container {
      width: 100%;
      margin: s.$XL;
    }
  }
}

@media only screen and (max-width: s.$width_xs) {
  #popup-alert {
    .alert-container {
      width: 100%;
      margin: s.$S;
    }
  }
}
