@use '/src/core-sass/style' as s;

#footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: s.$M;
  background-color: s.colors(darkBackground);
  padding: s.$M;
  border-top: 1px solid s.colors(orange);

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: s.$S;
  }

  li {
    list-style-type: none;
  }

  p, a {
    @include s.footerText(white);
  }

  a:hover {
    color: s.colors(orange);
  }
}

@media only screen and (max-width: s.$width_xxs) {
  #footer-bottom {
    ul {
      display: flex;
      flex-direction: column;

      &.legal-list {
        row-gap: s.$S;
      }

      .separator {
        display: none;
      }
    }
  }
}